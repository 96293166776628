import CryptoJS from "crypto-js"
export function encrypt(word, key) {
    var secretKey = CryptoJS.enc.Utf8.parse(key);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, secretKey, {    mode: CryptoJS.mode.ECB,    padding: CryptoJS.pad.Pkcs7  });
    return encrypted.toString();
}

export function decrypt(word, key) {
    var secretKey = CryptoJS.enc.Utf8.parse(key);
    var decrypt = CryptoJS.AES.decrypt(word, secretKey, {    mode: CryptoJS.mode.ECB,    padding: CryptoJS.pad.Pkcs7  });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}